<template>
  <v-card id="channel-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          @click="editChannel()"
        >
          <span>Kanal erstellen</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filter.search"
          single-line
          dense
          outlined
          hide-details
          placeholder="Kanal suchen"
          class="channel-list-search me-3"
        ></v-text-field>
        <v-select
          v-model="filter.angabeAktiv"
          :items="filterAngabeAktivOptions"
          item-text="description"
          item-value="angabeAktiv"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Aktiv"
          class="channel-list-angabeAktiv me-3"
        ></v-select>
        <v-select
          v-model="filter.angabeArchiviert"
          :items="filterAngabeArchiviertOptions"
          item-text="description"
          item-value="angabeAktiv"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Archiviert"
          class="channel-list-angabeArchiviert"
        ></v-select>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="listChannels"
      :options.sync="options"
      :server-items-length="totalAmountList"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :search="filter.search"
      class="text-no-wrap"
      no-data-text="Keine Einträge vorhanden"
      no-results-text="Ihre Suche ergab keinen Treffer"
      :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
    >
      <template
        v-slot:top
      >
        <v-dialog
          v-model="dialogDelete"
          width="500px"
        >
          <v-card>
            <v-card-title
              class="text-h5"
            >
              Möchten Sie den Kanal <strong>{{ deleteItem.name }}</strong> wirklich löschen?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="deleteChannelConfirm"
              >
                Löschen
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeDelete"
              >
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogEdit"
          width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      v-model="editItem.name"
                      label="Name"
                      outlined
                      :rules="[nameRules]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.memo"
                      label="Memo"
                      auto-grow
                      outlined
                      rows="3"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="editValidationError"
                @click="editChannelConfirm()"
              >
                Speichern
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeEdit()"
              >
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template #[`item.idNipKanal`]="{item}">
        #{{ item.idNipKanal }}
      </template>
      <template #[`item.name`]="{item}">
        <strong>{{ item.name }}</strong>
      </template>
      <template #[`item.memo`]="{item}">
        {{ item.memo }}
      </template>
      <template #[`item.angabeAktiv`]="{item}">
        <v-icon
          v-if="item.angabeAktiv === '1'"
          color="success"
          @click="setChannelState( item.idNipKanal, 0 )"
        >
          {{ icons.mdiCheckCircleOutline }}
        </v-icon>
        <v-icon
          v-else
          color="error"
          @click="setChannelState( item.idNipKanal, 1 )"
        >
          {{ icons.mdiAlertCircleOutline }}
        </v-icon>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="">

          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="editChannel( item )"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteChannel( item )"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Löschen</span>
          </v-tooltip>

        </div>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";

export default {
  name: 'channel-list',
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    return {
      vm
    }
  },
  data() {
    return {
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
      },
      filter: { search: '', angabeAktiv : '', angabeArchiviert : '', },
      loading: false,
      totalAmountList: 0,
      filterAngabeAktivOptions: [
        { angabeAktiv: 1, description: 'aktiv' },
        { angabeAktiv: 0, description: 'inaktiv' },
      ],
      filterAngabeArchiviertOptions: [
        { angabeAktiv: 1, description: 'archiviert' },
        { angabeAktiv: 0, description: 'nicht archiviert' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipKanal'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#ID',
          align: 'start',
          value: 'idNipKanal',
        },
        {text: 'Name', value: 'name'},
        {text: 'Memo', value: 'memo'},
        {text: 'Aktiv', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listChannels: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNlpKanal: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNlpKanal: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idNlpKanal: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,
    }
  },
  mounted() {
    this.fetchChannels()
  },
  watch: {
    options: {
      handler() {
        this.fetchChannels()
      },
    },
    filter: {
      handler() {
        this.fetchChannels()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Kanal hinzufügen' : 'Kanal bearbeiten'
    },
  },
  methods: {
    fetchChannels() {
      this.loading = true

      axios.post('api/kanal/', {
        aktion: 'showOverviewChannels',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listChannels = response.data.channels || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setChannelState(idNipKanal, state) {
      axios.post('api/kanal/', {
        aktion: 'changeChannelState',
        idNipKanal: idNipKanal,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchChannels()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteChannel (item) {
      this.dialogDelete = true
      this.deleteItem = Object.assign({}, item)
    },
    deleteChannelConfirm () {
      axios.post('api/kanal/', {
        aktion: 'deleteChannel',
        idNipKanal: this.deleteItem.idNipKanal,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchChannels()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    editChannel (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listChannels.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    nameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Name muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Name kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    editChannelConfirm () {
      axios.post('api/kanal/', {
        aktion: this.editIndex === -1 ? 'createChannel' : 'editChannel',
        data: this.editItem,
      })
        .then((response) => {
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.fetchChannels()
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
  .channel-list-angabeAktiv {
    max-width: 11.3rem;
  }
  .channel-list-angabeArchiviert {
    max-width: 11.3rem;
  }
}
</style>
